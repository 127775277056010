<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHAPEL FUND SUMMARY</h4></v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Date Requested</th>
              <th class="text-center text-uppercase">Credited To</th>
              <th class="text-center text-uppercase">Voucher #</th>
              <th class="text-center text-uppercase">Amount</th>
              <th class="text-center text-uppercase">Encoded</th>
              <th class="text-center text-uppercase">Balance</th>
              <th class="text-center text-uppercase">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in chapel_fund_items" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.date_credited }}
              </td>
              <td class="text-center">
                {{ item.credited_to }}
              </td>
              <td class="text-center">
                {{ item.voucher_no }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.encoded) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.balance) }}
              </td>
              <td class="text-center">
                <div>
                  <v-icon
                    v-if="!is_deleting"
                    class="mr-2"
                    color="success"
                    @click="print_data(chapel_fund_items.map(function(x) {return x.id; }).indexOf(item.id))"
                  >
                    {{icons.mdiPrinter}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
                <div v-if="position==='ADMIN SPECIAL'||position==='ADMIN'">
                  <v-icon
                    class="mr-2"
                    color="error"
                    v-if="!dialog"
                    @click="view_data(chapel_fund_items.map(function(x) {return x.id; }).indexOf(item.id))"
                  >
                    {{icons.mdiPencilBoxOutline}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" width="70%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CHAPEL FUND SUMMARY DATA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-data-table dense
                      disable-pagination
                      :headers="headers"
                      :items="chapel_fund_liquidation_data">
          <template v-slot:item="{ item }">
            <tr
            >
              <td>{{ item.id }}</td>
              <td>
                {{ item.payee }}
              </td>
              <td>
                {{ item.particulars.particulars }}
              </td>
              <td>
                {{ item.or_date }}
              </td>
              <td>
                {{ item.or_no }}
              </td>
              <td>
                {{ item.description }}
              </td>
              <td>
                {{ item.deceased === null ? '' : item.deceased.fsc_no }}
              </td>
              <td>
                {{ item.deceased === null ? '' : item.deceased.last_name + ', ' +
                item.deceased.first_name }}
              </td>
              <td>
                {{ formatPrice(item.amount) }}
              </td>
              <td class="text-center">
                <div>
                  <v-icon
                    class="mr-2"
                    color="error"
                    v-if="!dialog_edit"
                    @click="edit_data(chapel_fund_liquidation_data.map(function(x) {return x.id; }).indexOf(item.id))"
                  >
                    {{icons.mdiPencilBoxOutline}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_edit" width="50%">
      <v-card
        flat dense
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">EDIT DATA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-form ref="form" lazy-validation>
            <v-flex xs12>
              <v-layout row wrap align-center>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="or_date"
                    type="date"
                    label="OR Date"
                    class="mx-2"
                    required
                    :rules="rules.default_max_25_character_and_no_empty_rule"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md6>
                  <v-text-field
                    v-model="or_no"
                    label="OR No."
                    class="mx-2"
                    required
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="new_amount"
                    :min="0"
                    type="number"
                    label="Amount"
                    class="mx-2"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md4>
                  <v-text-field
                    v-model="payee"
                    label="Payee"
                    class="mx-2"
                    required
                    :rules="rules.default_max_75_character_and_no_empty_rule"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    v-model="description"
                    label="Description"
                    class="mx-2"
                    required
                  ></v-text-field>
                </v-flex>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert color="warning" text class="mb-0">
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{ alert_message }}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-btn color="primary" class="me-3 mt-4" @click="update_data" v-if="!saving"> Save
                    changes
                  </v-btn>
                  <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                       v-else></v-progress-circular>
                  <v-btn color="error" class="me-3 mt-4" @click="delete_data" v-if="!deleting">
                    Delete
                  </v-btn>
                  <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                       v-else></v-progress-circular>
                </v-col>
              </v-layout>
            </v-flex>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiPrinter, mdiPencilBoxOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      new_amount: 0,

      deleting: false,
      saving: false,
      alert: false,
      alert_message: '',

      or_date: '',
      or_no: '',

      payee: '',
      description: '',

      month_of: '',
      month_of_items: [],

      chapel_fund_items: [],
      is_deleting: false,
      dialog: false,
      dialog_edit: false,
      chapel_fund_liquidation_data: [],
      liquidation_data: {},
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'PAYEE', value: 'date_of_deposit', sortable: false},
        {text: 'PARTICULARS', value: 'time_of_deposit', sortable: false},
        {text: 'OR DATE', value: 'amount', sortable: false},
        {text: 'OR #', value: 'bank_code_name', sortable: false},
        {text: 'DESC.', value: 'bank_code_name', sortable: false},
        {text: 'FSC #', value: 'bank_code_name', sortable: false},
        {text: 'NAME', value: 'bank_code_name', sortable: false},
        {text: 'AMOUNT', value: 'bank_code_name', sortable: false},
        {text: 'ACTION', value: 'bank_code_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPrinter,
          mdiPencilBoxOutline,
        },
      }
    },
    created() {
      this.get_transaction_month_history({
        branch_id: this.branch_id,
      })
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id', 'branch', 'branch_address', 'branch_contact_no', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('chapel_fund', ['get_chapel_fund']),
      ...mapActions('chapel_fund_liquidation', ['update_chapel_fund_liquidation_admin', 'delete_chapel_fund_liquidation_admin']),
      monthly_remittances() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('month_of', this.month_of)
        this.get_chapel_fund(data)
          .then(response => {
            this.chapel_fund_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      view_data(index) {
        this.dialog = true
        this.chapel_fund_liquidation_data = this.chapel_fund_items[index].liquidations
      },
      edit_data(index) {
        this.dialog_edit = true
        this.liquidation_data = this.chapel_fund_liquidation_data[index]
        this.or_date = this.liquidation_data.or_date
        this.or_no = this.liquidation_data.or_no
        this.new_amount = this.liquidation_data.amount
        this.payee = this.liquidation_data.payee
        this.description = this.liquidation_data.description
      },
      update_data() {
        this.saving = true
        this.deleting = true
        this.alert = false
        if (this.$refs.form.validate()) {
          this.update_chapel_fund_liquidation_admin({
            id: this.liquidation_data.id,
            chapel_fund_id: this.liquidation_data.chapel_fund_id,
            branch_id: this.liquidation_data.branch_id,
            payee: this.payee,
            or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
            or_no: this.or_no,
            old_amount: this.liquidation_data.amount,
            amount: this.new_amount,
            description: this.description,
          })
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.dialog = false
              this.dialog_edit = false
              this.saving = false
              this.deleting = false
              this.monthly_remittances()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
              this.deleting = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
          this.deleting = false
        }
      },
      delete_data() {
        this.saving = true
        this.deleting = true
        this.alert = false
        if (this.$refs.form.validate()) {
          this.delete_chapel_fund_liquidation_admin({
            id: this.liquidation_data.id,
            chapel_fund_id: this.liquidation_data.chapel_fund_id,
            branch_id: this.liquidation_data.branch_id,
            payee: this.payee,
            or_date: moment(this.or_date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
            or_no: this.or_no,
            old_amount: this.liquidation_data.amount,
            amount: this.new_amount,
            description: this.description,
          })
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.dialog = false
              this.dialog_edit = false
              this.saving = false
              this.deleting = false
              this.monthly_remittances()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
              this.deleting = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
          this.deleting = false
        }
      },
      print_data(index) {
        var imgData = this.company_logo
        var payments_array = []
        var header_array = []
        var details_array = []

        var month = this.month_of
        var date_credited = this.chapel_fund_items[index].date_credited
        var credited_to = this.chapel_fund_items[index].credited_to
        var voucher_no = this.chapel_fund_items[index].voucher_no
        var prev_balance = 0
        var amount = this.chapel_fund_items[index].amount
        var balance = this.chapel_fund_items[index].balance
        var total_amount = 0
        var details = this.chapel_fund_items[index].data

        if ((index - 1) >= 0) {
          prev_balance = this.chapel_fund_items[index - 1].balance
        }
        header_array.push(
          [
            {text: 'Date Credited', alignment: 'center', style: 'label'},
            {text: 'Credited To', alignment: 'center', style: 'label'},
            {text: 'Voucher #', alignment: 'center', style: 'label'},
            {text: 'Prev Balance', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
            {text: 'Balance', alignment: 'center', style: 'label'},
            {text: '', alignment: 'center', style: 'label'},
          ]
        )
        header_array.push(
          [
            {text: date_credited, alignment: 'center'},
            {text: credited_to, alignment: 'center'},
            {text: voucher_no, alignment: 'center'},
            {
              text: (prev_balance / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'center'
            },
            {
              text: (amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'center'
            },
            {
              text: (balance / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'center'
            },
            {text: '', alignment: 'center'},
          ]
        )
        details_array.push(
          [
            {text: 'Particular', alignment: 'center', style: 'label'},
            {text: 'Total Amount', alignment: 'center', style: 'label'},
          ]
        )
        if (details.length > 0) {
          details.forEach(function (item) {
            details_array.push(
              [
                {text: item.particulars, alignment: 'right'},
                {
                  text: item.amount,
                  alignment: 'left'
                },
              ]
            )
          })
        } else {
          details_array.push(
            [
              '----',
              '----',
            ]
          )
        }
        payments_array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'Payee', alignment: 'center', style: 'label'},
            {text: 'Particulars', alignment: 'center', style: 'label'},
            {text: 'OR Date', alignment: 'center', style: 'label'},
            {text: 'OR #', alignment: 'center', style: 'label'},
            {text: 'Description', alignment: 'center', style: 'label'},
            {text: 'FSC #', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'}
          ]
        )
        if (this.chapel_fund_items[index].liquidations.length > 0) {
          this.chapel_fund_items[index].liquidations.forEach(function (item, index, payment) {
            total_amount = total_amount + parseFloat(item.amount)
            payments_array.push(
              [
                {text: item.id, alignment: 'left'},
                {text: item.payee, alignment: 'left'},
                {text: item.particulars.particulars, alignment: 'left'},
                {text: item.or_date, alignment: 'left'},
                {text: item.or_no, alignment: 'left'},
                {text: item.description, alignment: 'left'},
                {text: item.deceased === null ? '' : item.deceased.fsc_no, alignment: 'left'},
                {
                  text: item.deceased === null ? '' : item.deceased.last_name + ', ' + item.deceased.first_name,
                  alignment: 'left'
                },
                {
                  text: (item.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total:',
                    color: 'red',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    color: 'red',
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'CHAPEL FUND SUMMARY REPORT', style: 'title'},
            '============================================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            {
              style: 'tableExample',
              table: {
                widths: [92, 92, 92, 92, 92, 92, 92],
                body: header_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#d9b4b4' : null;
                },
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            ' ',
            {
              style: 'tableExample',
              table: {
                widths: [585, 100],
                body: details_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#03aa89' : null;
                },
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [40, 50, 90, 60, 40, 150, 50, 100, 60],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Cashier', style: 'aa_placeholder'},
                    {text: 'Name', style: 'cashier_placeholder'},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'cs_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cs_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Chapel Manager', style: 'bm_placeholder'},
                    {text: 'Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          // footer: {
          //   columns: [
          //     {
          //       text: 'Generated with Goodlife Savings & Credit Cooperative System',
          //       alignment: 'center',
          //       style: 'tableExample'
          //     }
          //   ]
          // },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 10
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
